import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql } from 'gatsby'
import { connect } from 'react-redux'

import propTypes from 'prop-types'

import Layout from '../../layouts/default'
import Banner from '../../components/sections/_banner/default'
import Heading from '../../components/app-components/dashed-heading'
import ForgetPasswordForm from '../../components/app-components/forget-password-form'
import Icon from '../../components/app-components/icon'
import Button from '../../components/app-components/button'

import { doResetPassword } from '../../store/authentication/actions'
import './styles.scss'

const ForgotPasswordTemplate = ({
  location,
  resetPassword,
  data: {
    wpPage: { seo },
  },
}) => {
  const [success, setSuccess] = useState(false)
  const reset = async email => {
    const res = await resetPassword(email)
    if (res) setSuccess(true)
    return res
  }
  return (
    <Layout seo={seo}>
      <Banner title="Connexion" img="default" bannerClass="login-banner" />
      <Container className="reset-password-page py-5">
        <Row>
          <Col>
            <Heading
              className="reset-password-page-title"
              title="Mot de passe oublié"
            />
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <Icon name="icon-password" iconClass="login-wrapper" paths={10} />
            {success ? (
              <p className="form-dec pt-2 pb-4">
                Votre <strong>demande de réinitialisation</strong> a bien été{' '}
                <strong>prise en compte</strong>. Nous vous enverrons un{' '}
                <strong>mot de passe provisoire</strong> afin de récupérer votre
                compte.
              </p>
            ) : (
              <p className="form-dec pt-2 pb-4">
                <strong>Pour réinitialiser votre mot de passe,</strong>{' '}
                saisissez votre adresse mail. Puis, nous vous enverrons un mot
                de passe provisoire afin de récupérer votre compte.
              </p>
            )}
          </Col>
        </Row>
        <Row>
          {!success ? (
            <Col lg={{ span: 10, offset: 1 }}>
              <ForgetPasswordForm
                handleSubmit={reset}
                email={location.state ? location.state.email : ''}
              />
            </Col>
          ) : (
            <Col className="text-center">
              <Button
                name="Accéder à mon compte"
                variant="secondary"
                to="/connexion"
              />
            </Col>
          )}
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ForgotPasswordPage($id: String!) {
    wpPage(id: { eq: $id }) {
      ...yoastMeta
    }
  }
`

ForgotPasswordTemplate.propTypes = {
  location: propTypes.shape({ state: { email: propTypes.string.isRequired } })
    .isRequired,
  resetPassword: propTypes.func.isRequired,
  data: propTypes.objectOf(propTypes.any).isRequired,
}

const mapDispatchToProps = dispatch => ({
  resetPassword: email => dispatch(doResetPassword(email)),
})
export default connect(null, mapDispatchToProps)(ForgotPasswordTemplate)
