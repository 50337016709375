import React, { useState } from 'react'
import { Form, Container, Row, Col, Button } from 'react-bootstrap'
import useForm from 'react-hook-form'
import * as yup from 'yup'
import propTypes from 'prop-types'

import ErrorMessage from '../form-error-message'

const ForgetPasswordForm = ({ handleSubmit }) => {
  const [email, setEmail] = useState('')

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .required()
      .email()
      .max(100),
  })
  const {
    register,
    errors,
    formState,
    setValue,
    setError,
    handleSubmit: wrapSubmit,
  } = useForm({
    validationSchema: formSchema,
    mode: 'onChange',
  })
  const handleChange = ({ target: { value, name } }) => {
    // validate form on input change
    setValue(name, value, true)
    setEmail(value)
  }

  const resetPassword = async e => {
    e.preventDefault()
    const res = await handleSubmit(email)
    if (!res) setError([{ name: 'email', type: 'notMatch' }])
  }

  return (
    <Container fluid className="forget-password-form">
      <Row className="justify-content-center">
        <Col md={6} sm={6}>
          <Form onSubmit={e => wrapSubmit(resetPassword(e))}>
            <Form.Group className="form-label-group" controlId="email">
              <Form.Control
                type="email"
                placeholder="Email"
                name="email"
                ref={register}
                onChange={handleChange}
                isInvalid={typeof errors.email !== 'undefined'}
              />
              <Form.Label>Email</Form.Label>
              <ErrorMessage name="email" errors={errors} />
            </Form.Group>
            <div className="text-center">
              <Button
                className="mt-4"
                disabled={!formState.isValid}
                type="submit"
                size="lg"
                variant={
                  formState.dirty && formState.isValid
                    ? 'secondary'
                    : 'disabled'
                }
              >
                RÉINITIALISER
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

ForgetPasswordForm.propTypes = {
  handleSubmit: propTypes.func.isRequired,
}

export default ForgetPasswordForm
